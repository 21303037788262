import { Tab } from '../services/models/tab';

export class UiUtility {

  // Set Width of child columns proportionally of 100% or specified value (totalWidth) if no width param being passed
  public static SetTabsWidthDynamically(parent: Element, width: number = 0, pxAdjustment: number = 0, totalWidth: number = 100): void {
    if (!parent) {
      return;
    }

    let childNodes: Element[] = [];
    for (const child of Array.from(parent.children as HTMLCollection)) {
      if (!child.attributes['aria-hidden'] || child.attributes['aria-hidden'] === false) {
        childNodes.push(child);
      }
    }

    if (width === 0 && pxAdjustment === 0) {
      width = totalWidth / (parent.children ? parent.childElementCount : 1);
    }

    if (width === 0 && pxAdjustment >= 0) {
       // set width with pixels value
      width = pxAdjustment;
      for (const child of childNodes) {
        child.setAttribute('style', 'width:' + width + 'px;');
      }
    }
    else {
       // set width with % value plus pixels if passed
      for (const child of childNodes) {
        if (pxAdjustment > 0) {
          child.setAttribute('style', 'width:' + width + '%; width:calc(' + width + '% - ' + pxAdjustment + 'px);');
        } else {
          child.setAttribute('style', 'width:' + width + '%;');
        }
      }
    }
  }

  public static isNullOrUndefined(value): boolean {
    if (value == null) {
      return true;
    }

    if (value === null) {
      return true;
    }

    if (typeof value === 'undefined') {
      return true;
    }

    return false;
  }

  public static isNullUndefinedOrEmpty(value): boolean {
    if (value == null) {
      return true;
    }

    if (value === null) {
      return true;
    }

    if (typeof value === 'undefined') {
      return true;
    }

    let test = typeof(value);
    if (typeof(value) === 'string' && value.length === 0) {
      return true;
    }

    return false;
  }

  public static SendBrowserAgnosticEvent<T extends HTMLElement | Window>(elem: T, eventName: string): Event {
    let event;
    if (typeof(Event) === 'function') {
        event = new Event(eventName);
    } else {
        event = document.createEvent('Event');
        event.initEvent(eventName, true, true);
    }
    return event;
  };

  public static convertBase64ToArrayBuffer(base64value: string) {
    var binaryStr = window.atob(base64value);
    var binaryLen = binaryStr.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       var ascii = binaryStr.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
 }

 public static getSupportedBrowserName() {
  const userAgent = window.navigator.userAgent.toLowerCase();
  switch (true) {
    case userAgent.indexOf('edge') > -1:
      return 'edge';
    case userAgent.indexOf('chrome') > -1 && !!(<any>window).chrome:
      return 'chrome';
    case userAgent.indexOf('firefox') > -1:
      return 'firefox';
    case userAgent.indexOf('safari') > -1:
      return 'safari';
    default:
      return 'other';
  }
 }

 public static validateEmail(email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
 }

 public static getTabByName(label: string, tabs: Tab[] = []) {
  const currentTab = tabs.find((tab: Tab) => tab.label === label);
  return currentTab;
 }

 public static getTabByIndex(index: number, tabs: Tab[] = []) {
  const currentTab = tabs.find((tab: Tab) => tab.index === index);
  return currentTab;
 }

 public static scrollDown(document: any) {
  let el = document.getElementsByClassName("page");
  el[0].scrollTop = el[0].scrollHeight;
 }

 public static format(value:string, ...args: any[]) {
  return value.replace(/\{(\d+)\}/g, function() {
      return args[arguments[1]];
  });
};
}
