export enum UserType {
  EnterpriseAdmin,
  LocalOfficeAdmin,
  InternalUser,
  ExternalUser
};

export enum UserRoleType {
  Unknown = 0,
  EnterpriseAdmin = 1,
  LocalOfficeAdmin = 2,
  EligibilityViewer = 3,
  ClaimsViewer = 4,
  PCP = 5,
  CustomSetup = 6,
  MedicalProfessional = 7,
  CustomEnt = 8,
  CustomPrv = 9
};

export enum UserStatus {
  All,
  Incomplete,
  Pending,
  Approved,
  Active,
  Declined,
  Deactivated,
  Inactive,
  PasswordExpired,
  LOCKED,
  Reinstated,
  AdminLocked,
  ResetPasswordLocked
};

export class PortalUser {
  public secureAuthUserName: string;
  public userID: string;
  public userSuspenseID?: number;
  public name: string;
  public suspenseId?: number;
  public providerCollectionID?: number;
  public userProviderCollectionXrefID?: number;
  public roleID: number;
  public roleCode: string;
  public collectionName?: string;
  public email: string;
  public userType?: UserType;
  public status: UserStatus;
  public createDate?: Date;
  public assignedToUserName?: string[];
  public assignedtoEmail?: string[];
  public internalName?: string;
  public isInCollection?: boolean;
  public statusDate: string;
  public isEnterpriseUserFlag: boolean;
  public notesText: string;
  public userLastVerified: Date;
  public userLastVerifiedBy: string;
  public userXrefEffDate: Date;
  public userXrefTermDate: Date;
  public isVerified: boolean;
  public hasVerificationChange: boolean;
  public providerPositionID: number;
  public positionDisplayName: string;
  public providerPositionOther: string;
}
