import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AppConfig } from '@caloptima/portal-foundation';
import { AuthenticationService, AuthHelper } from '@caloptima/authentication';
import { Subscription, Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';

import { BusyService } from '../services/busy.service';
import { UserAdminService } from '../services/user-admin.service';
import { SignalRClientService } from '../services/signalr-client.service';
import { UserPermission } from '../services/models/user-permission';
import { Constants } from '../app.constants';
import { PortalConfig } from '../portal-config';
import { distinctUntilChanged } from 'rxjs/operators';
import { ProviderSetting } from './models/provider-setting';

@Injectable({
  providedIn: 'root'
})
// A service to manage the attributes related to a session, such as their current permissions
export class SessionService {
  private collectionSubscription: Subscription;
  private permissionsSubject$ = new ReplaySubject<UserPermission>();
  private sessionWarningSubject$ = new BehaviorSubject<number>(0);
  private passwordResetWarningSubject$ = new BehaviorSubject<number>(0);
  private isSessionTerminatedSubject$ = new BehaviorSubject<boolean>(true);
  private isPasswordResetLockedSubject$ = new BehaviorSubject<boolean>(false);

  private organizationsSubject$ = new ReplaySubject<UserPermission[]>();
  public organizationsChanged$ = this.organizationsSubject$.asObservable()
    .pipe(
      distinctUntilChanged()
    );
  private providerSettingsSubject$ = new ReplaySubject<ProviderSetting[]>();
  public providerSettingsChanged$ = this.providerSettingsSubject$.asObservable()
    .pipe(
      distinctUntilChanged()
    );

  private enterpriseAdminFlag: boolean;
  private enterpriseUserFlag: boolean;
  private adminFlag: boolean;
  private isDefaultCollectionAvailable: boolean;

  public hasMultipleCollections: boolean;
  public hasCollectionAdmin: boolean; // user is an Admin at least in one of collection
  public isUserVerifiedDue: boolean
  public isCollectionVerifiedWarning: boolean;
  public isCollectionVerifiedDue: boolean;
  public isHipaaTrainingDue: boolean;
  public isAdminLocked: boolean;
  public isDeactivated: boolean;
  public collectionLastVerifiedDate: Date;
  public permissions: UserPermission[];
  public currentPermission: UserPermission;
  public isDefaultCollectionJustSet = false;
  public daysLeftToResetPassword: number = -1;
  public isPasswordRestLocked: boolean;
  public isJivaReferralImplementation: boolean = false;

  constructor(
    private constants: Constants,
    private appConfig: AppConfig,
    private configuration: PortalConfig,
    private adminService: UserAdminService,
    private signalR: SignalRClientService,
    private oauth: OAuthService,
    private authService: AuthenticationService,
    private authHelper: AuthHelper,
    private busy: BusyService,
    private router: Router
  ) {
    this.enterpriseAdminFlag = false;
    this.enterpriseUserFlag = false;
    this.adminFlag = false;
    this.isUserVerifiedDue = false;
    this.isCollectionVerifiedDue = false;
    this.isCollectionVerifiedWarning = false;
  }

  public setCurrentPermission(permission: UserPermission): void {
    this.currentPermission = permission;
    if (permission != null) {
      this.adminFlag =  this.currentPermission.isAdmin;
      this.permissionsSubject$.next(permission);
    }
    else {
      this.permissionsSubject$.next(null);
    }
  }

  private setOrganizations(permissions: UserPermission[]): void {
    if (permissions != null) {
      this.organizationsSubject$.next(permissions);
    }
    else {
      this.organizationsSubject$.next(null);
    }
  }

  private setProviderSettings(providerSettings: ProviderSetting[]): void {
    if (providerSettings != null) {
      this.providerSettingsSubject$.next(providerSettings);
    }
    else {
      this.providerSettingsSubject$.next(null);
    }
  }

  // Event sent when the permissions have changed.  This may happen if the
  // user selects a different collection.
  public permissionsChanged(): Observable<UserPermission> {
    return this.permissionsSubject$.asObservable();
  }

  // Event sent when the current session is within a minute of timing out.
  public timeOutWarning(): Observable<number> {
    return this.sessionWarningSubject$.asObservable();
  }

  public passwordResetWarningSubject(): Observable<number> {
    return this.passwordResetWarningSubject$.asObservable();
  }

  public isPasswordResetLockedSubject(): Observable<boolean> {
    return this.isPasswordResetLockedSubject$.asObservable();
  }

  // Event sent when the current session has terminated
  public isSessionTerminated(): Observable<boolean> {
    return this.isSessionTerminatedSubject$.asObservable();
  }

  // Returns true if the current user is an enterprise (CalOptima) user.
  public get isEnterpriseUser(): boolean {
    return this.enterpriseUserFlag;
  }

  // Returns true if the current user is an enterprise administrator
  public get isEnterpriseAdmin(): boolean {
    return this.enterpriseAdminFlag;
  }

  // Returns true if the current user is a local administrator of the current collection.
  public get isAdmin(): boolean {
    return this.adminFlag;
  }

  public get isAdminCollectionWarning() {
    return this.isCollectionVerifiedWarning;
  }

  public get showOrganizationsDashboardPopUp(): boolean {
    return this.hasMultipleCollections && !this.isDefaultCollectionAvailable;
  }

  public hasPermission(codes: string[]): boolean {
    let allowed: boolean = false;
    codes.forEach(code => {
      if (!allowed && this.currentPermission != null) {
        allowed = this.currentPermission.userPermissionCodes.find(x => x === code) != null;
      }
    });

    return allowed;
  }

  public verifyUser(): boolean {
    let verified: boolean = true;

    if(this.isAdminLocked || this.isDeactivated){
      verified = false;
    }
    else if (this.adminFlag && this.isCollectionVerifiedDue) {
      verified = false;
    }
    else if (this.isUserVerifiedDue) {
      verified = false;
    }
    return verified;
  }

  public verifyHipaaTraining(): boolean {
    let hipaaTrainingTaken: boolean = true;

    if (this.isHipaaTrainingDue) {
      hipaaTrainingTaken = false;
    }
    return hipaaTrainingTaken;
  }

  // Start a new session if the user is logged on.  the is a single location to manage the session related attributes,
  // such as permissions and session timeout.
  public start(): void {
    if (this.oauth.getIdentityClaims() != null) {
      this.busy.emit(true);
      const sub = this.adminService.findUserPermissions().subscribe(result => {
        this.passwordResetWarningSubject$.next(result.daysLeftToResetPassword);
        this.permissions = result.permissions;
        this.enterpriseAdminFlag = result.isEnterpriseAdmin;
        this.enterpriseUserFlag = result.isEnterpriseUser;
        this.hasCollectionAdmin = result.hasCollectionAdmin; // has at least admin in one of collection
        this.isPasswordResetLockedSubject$.next(result.isPasswordResetLocked);
        this.isSessionTerminatedSubject$.next(false);
        this.isJivaReferralImplementation = result.referralSetting ? result.referralSetting.isJivaImplementation : false;

        //multi org
        this.isDefaultCollectionAvailable = result.defaultProviderCollectionID > 0;
        this.hasMultipleCollections = this.permissions.length > 1;
        this.setCurrentPermission(this.hasMultipleCollections
          ? this.permissions.find(c => c.providerCollectionID === c.defaultProviderCollectionID || c.providerCollectionID === 0)
          : this.permissions[0]);
        this.setOrganizations(this.permissions);
        this.setProviderSettings(result.providerSettings);

        this.permissions.forEach(perm => {
          if (perm.isAdmin) {
            this.isCollectionVerifiedDue = !this.isCollectionVerifiedDue ? perm.isCollectionLastVerifiedDue : this.isCollectionVerifiedDue;
            if (!this.isCollectionVerifiedWarning) {
              this.isCollectionVerifiedWarning = perm.isCollectionLastVerifiedWarning;
              this.collectionLastVerifiedDate = new Date(perm.collectionLastVerifiedDate);
            }
            else {
              this.isCollectionVerifiedWarning = this.isCollectionVerifiedWarning;
            }
          }
          else {
            this.isUserVerifiedDue = !this.isUserVerifiedDue ? perm.isUserLastVerifiedDue : this.isUserVerifiedDue;
          }
          this.isAdminLocked = !this.isAdminLocked ? perm.isAdminLocked : this.isAdminLocked;
          this.isDeactivated = !this.isDeactivated ? perm.isDeactivated : this.isDeactivated;
          this.isHipaaTrainingDue = !this.isHipaaTrainingDue ? perm.isHipaaTrainingDue : this.isHipaaTrainingDue;
        });

        if (this.permissions.length > 0) {
          this.isCollectionVerifiedWarning = this.isCollectionVerifiedWarning && !this.isCollectionVerifiedDue;
        }
        if (sub != null) {
          sub.unsubscribe();
        }
        this.busy.emit(false);
      });

      this.authHelper.tokenExpiring(this.constants.TokenExpirationWarningInSeconds).subscribe(() => {
        // the access token will be expiring very soon, so refresh the token.
        const getToken$ = this.authService.getRefreshToken(this.configuration.ClientId).subscribe((resp) => {
          const loginSub$ = this.authHelper.oauthLogin(resp).subscribe(() => {
            loginSub$.unsubscribe();
          });
          if (getToken$ != null) {
            getToken$.unsubscribe();
          }
        });
      });
    }
  }

  // End the current session.  logs out the user, disconnects server side notifications
  // This may be called expicitly such as from the menu, or may be called implicily
  // from the session timer.
  public end(): void {
    if (this.collectionSubscription != null) {
      this.collectionSubscription.unsubscribe();
      this.collectionSubscription = null;
    }

    this.signalR.disconnect();
    this.oauth.logOut(true);
    this.isSessionTerminatedSubject$.next(true);
    sessionStorage.removeItem(this.configuration.SessionStateKey);
    sessionStorage.clear();
    this.router.navigate([this.configuration.LogoutUrl]);
  }
}
